import {ChangeDetectorRef, Component, EventEmitter, HostListener, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {StateService} from 'src/app/core/services/state/state.service';
import {AlertService} from 'src/app/shared/alert/services/alert.service';
import {PeopleList} from '../../../services/people_list.service';
import {ApiPeopleService} from '../../../services/api-people.service';
import {ApiJobsService} from 'src/app/jobs/services/api-jobs.service';
import {EvaluationHelper} from 'src/app/shared/misc/evaluation.helper';
import {TextHelper} from 'src/app/shared/misc/text.helper';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';
import {first, take} from 'rxjs/operators';
import {WindowRef} from '../../../../app/services/window-ref.service';
import {TestAndReportHandlingService} from '../../../services/test-and-report-handling.service';
import {PersonService} from '../../../services/person.service';
import {ComponentPrimitive} from 'src/app/shared/classes/components/componentPrimitive';
import {DeviceDetectorService} from 'ngx-device-detector';
import {UserService} from 'src/app/core/services/user/user.service';
import {EnvService} from '../../../../app/services/env.service';

@Component({
    selector: 'app-mobile-people-full-view',
    templateUrl: './mobile-people-full-view.component.html',
    styleUrls: ['./mobile-people-full-view.component.scss']
})
export class MobilePeopleFullViewComponent extends ComponentPrimitive implements OnInit, OnDestroy {
    @Output() unaccessibleTest = new EventEmitter;
    @ViewChild('matInfoPanel') matInfoPanel;

    loader: string;
    archive = false;
    config = 'report';
    reportType: string;
    mpoSendType: string;
    resendType: string;
    resendData: any;
    originReportType = '';
    activationData: any;
    displayRequest = false;
    pdfRequest = false;
    editRequest = false;
    activationRequest = false;
    mpoSendRequest = false;
    prbSendRequest = false;
    iacSendRequest = false;
    resendRequest = false;
    queryReport: boolean;
    jobToDisplay;
    communicationPrb: boolean;
    satelliteJobs: any;
    satelliteLevel: any;
    satelliteDescription: any;
    titleSep: string;
    pDateFormat: string;
    prb: any = null;
    communicationPortraitName: string;
    communicationPrbPortraitName: any;
    communicationQuad: string;
    communicationPrbQuad: any;
    communicationAd: string;
    communicationPrbAd: any;
    communicationGraphType: string;
    communicationPrbGraphType: any;
    communicationPrbDate: string;
    requestedBy: string;
    evaluationRequests: any;
    graphWidth: number;
    graphHeight: number;
    report: any;
    buttonsFolder = '/assets/buttons/';
    expandInfoPanel = false;
    nativeWindow: any;
    evaluationFormUrl: string;
    isLandscape = false;
    showPortraitButtons = false;
    idleTime = 0;
    // idleInterval;
    sessionData: any;
    prbGridCols = 3;
    prbGridTile1 = 2;
    showIacQuestionnaire = true;

    private subscriptions = new Subscription();

    constructor(
        public stateService: StateService,
        public translate: TranslateService,
        private changeDetectorRef: ChangeDetectorRef,
        private apiJobs: ApiJobsService,
        private apiPeopleService: ApiPeopleService,
        private alertService: AlertService,
        protected router: Router,
        private windowRef: WindowRef,
        protected route: ActivatedRoute,
        public user: UserService,
        public person: PersonService,
        public peopleList: PeopleList,
        protected deviceService: DeviceDetectorService,
        public testAndReportHandling: TestAndReportHandlingService,
        private environment: EnvService
    ) {
        super(stateService, translate, router, deviceService);
        this.nativeWindow = windowRef.getNativeWindow();
        this.evaluationFormUrl = this.environment.evaluationFormUrl;
    }

    @HostListener('window:mousemove') refreshUserState() {
        this.idleTime = 0;
    }

    setSubAccountName(subAccountNumber) {
        let name = null;
        if (subAccountNumber >= 0) {
            for (let subAccData of this.peopleList.sessionData.structure.subAccounts) {
                if (subAccData.id === subAccountNumber) {
                    name = subAccData.name;
                    // noinspection BreakStatementJS
                    break;
                }
            }
        }
        return name;
    }

    getPersonCommunicationPRB() {
        if (this.person.evaluations && this.person.evaluations.prb && this.person.evaluations.prb.list[this.peopleList.state.prbIndex] && this.person.evaluations.prb.list[this.peopleList.state.prbIndex]['info']['status']) {
            if (this.person.evaluations.prb.list[this.peopleList.state.prbIndex].info.requestIn) {
                return this.person.evaluations.prb.list[this.peopleList.state.prbIndex + 1].info.additionalData.dit;
            } else {
                return this.person.evaluations.prb.list[this.peopleList.state.prbIndex].info.additionalData.dit;
            }
        }
        return null;
    }

    getPersonPRB() {
        if (this.person.evaluations && this.person.evaluations.prb) {
            if (this.person.evaluations.requests &&
                this.person.evaluations.requests[0] &&
                this.person.evaluations.requests[0].variant === '0,1'
            ) {
                if (this.person.evaluations.prb.list[0].scores !== 'request') {
                    this.person.evaluations.prb.info.size++;
                    // noinspection IncrementDecrementResultUsedJS
                    this.person.evaluations.prb.list.splice(0, 0, {
                        info: {
                            status: true,
                            date: this.person.evaluations.requests[0].date.sec * 1000,
                            lastPrbIndex: this.person.evaluations.prb.info.lastPrbIndex++,
                            requestIn: true
                        },
                        scores: 'request'
                    });
                }
            }
            return this.person.evaluations.prb;
        }
        return null;
    }

    getPersonRequests() {
        if (this.person.evaluations && this.person.evaluations.requests) {
            this.displayRequest = true;
            return this.person.evaluations.requests;
        } else {
            this.displayRequest = false;
        }
        return null;
    }

    getPersonRequest(idRequest = 0) {
        if (this.getPersonRequests() && this.getPersonRequests()[idRequest]) {
            return this.getPersonRequests()[idRequest];
        }
        return null;
    }

    isNeededTempUser(type) {
        let requests = (this.person.evaluations && this.person.evaluations.requests) ? this.person.evaluations.requests : null;
        if (!requests) {
            return null;
        }
        let requestsByType = [];

        let variant;
        if (type === 'prb') {
            variant = ['0,1'];
        } else if (type === 'ra') {
            variant = ['0,0'];
        } else if (type === 'mpo') {
            variant = ['1,1', '1,0'];
        } else {
            throw 'invalid type given at mobile-people-full-view.component :: isNeededTempUser()';
        }

        for (let i in requests) {
            if (requests.hasOwnProperty(i)) {
                if (variant.includes(requests[i].variant) || (type === 'ra' && requests[i].hasSurveyRa)) {
                    requestsByType.push(requests[i]);
                }
            }
        }

        return (requestsByType.length) ? requestsByType[0].createTempUser : null;
    }

    resendQuestionnaire(recordId: string = null, isTempUser: boolean = null, type = 'resendMpo') {
        let evaluationWindow = null;

        if (!isTempUser) {
            // Open window to avoid popup blocking
            evaluationWindow = this.nativeWindow.open('', '_blank');
        }

        this.subscriptions.add(this.apiPeopleService.evaluationRequestResend([recordId, 0]).pipe(first()).subscribe(
            resendData => {
                if (isTempUser) {
                    this.resendRequest = true;
                    this.resendData = {
                        tempUser: resendData,
                        person: this.person.personToDisplay,
                        type: type
                    };
                    this.changeDetectorRef.markForCheck();
                    // this.requestResendEvent.emit({'tempUser': resendData, 'person': this.person.personToDisplay});
                } else {
                    evaluationWindow.document.location.href = this.evaluationFormUrl + '/autoconnect/' + resendData.token;
                }
            },
            error => {
                console.log(error);
            }
        ));
    }

    get satelliteJob() {
        if (
            this.person.evaluations &&
            this.person.evaluations.satellite &&
            this.person.evaluations.satellite.info &&
            this.person.evaluations.satellite.info.job &&
            this.person.evaluations.satellite.info.job.id
        ) {
            return this.person.evaluations.satellite.info.job.id;

        }
        return false;
    }

    translationItem(which: string) {
        if (which === 'date') {
            return (this.translate.currentLang === 'fr') ? 'dd/MM/y' : 'MM/dd/y';
        }
    }

    updateIdwPrbGraph() {
        if (this.getPersonCommunicationPRB()) {
            this.communicationPrbPortraitName =
                this.testAndReportHandling.renderQuad(this.getPersonCommunicationPRB().quad);
            this.communicationPrbDate = (this.getPersonCommunicationPRB()) ?
                this.person.evaluations.prb.list[this.peopleList.state.prbIndex].info.date :
                '';
            this.communicationPrbQuad = this.getPersonCommunicationPRB().quad;
            this.communicationPrbAd = this.getPersonCommunicationPRB().adaptability;
            this.communicationPrbGraphType = 'active';
            this.requestedBy = (this.person.communicationRequestedBy) ? this.person.communicationRequestedBy.fullName : '';
        } else {
            this.communicationPrbQuad = undefined;
            this.communicationPrbAd = undefined;
            this.communicationPrbGraphType = 'inactive';
            if (this.getPersonRequest()) {
                this.requestedBy = (this.getPersonRequest().requestedBy) ? this.getPersonRequest().requestedBy : '';
            }
        }
    }

    questionnaireCompleted() {
        return !!(this.person.personalityReport ||
            this.person.communicationReport ||
            this.person.satelliteReport ||
            this.person.talentsReport);
    }

    showMpoSendRequest(rType = 'mpo', expandInfoPanel = false) {
        if (this.displayResendAndDeleteIac) {
            this.showWarning('sendMpo');
        } else {
            this.mpoSendRequest = true;
            this.mpoSendType = rType;
            if (expandInfoPanel) {
                this.expandInfoPanel = true;
            }
        }
    }

    showPrbSendRequest(expandInfoPanel = false) {
        this.prbSendRequest = true;
        if (expandInfoPanel) {
            this.expandInfoPanel = true;
        }
    }

    activate(type, expandInfoPanel = false) {
        let evalType = this.relabel(type);
        if (!this.archive) {
            // PRB related
            let prbRelated: number | null = null;
            if (evalType === 'mpo') {
                prbRelated = EvaluationHelper.getPrbRelatedFromEvaluations(this.person.evaluations);
            }
            this.activationData = {
                type: type,
                personToDisplay: {
                    id: this.person.id,
                    subAccount: this.person.subAccount,
                    email: this.person.email,
                    firstName: this.person.firstName,
                    lastName: this.person.lastName,
                    jobTitle: this.person.jobTitle,
                    organisation: this.person.organisation,
                    recordType: this.person.recordType,
                    jobId: this.person.jobId
                },
                prbRelated: prbRelated,
                emission: {
                    personToDisplay: {},
                    reportType: type
                }
            };
            this.activationRequest = true;
            if (expandInfoPanel) {
                this.expandInfoPanel = true;
            }
            this.changeDetectorRef.markForCheck();
        }
    }

    getProperty(ctx, property) {
        if (ctx[property] !== null && ctx[property] !== undefined && ctx[property] !== '') {
            return ctx[property];
        }
        return null;
    }

    checkPrb() {
        if (this.config === 'list') {
            this.prb = this.testAndReportHandling.getPrbData(this.getPersonPRB(), 0, this.mpoGraphType);
        } else {
            this.prb = this.testAndReportHandling.getPrbData(this.getPersonPRB(), this.peopleList.state.prbIndex, this.mpoGraphType);
        }
    }

    statusIcon(obj, key) {
        let statusTxt;
        if (key === 'iac') {
            key = 'ra';
        }
        if (obj.testStatuses && obj.testStatuses[key]) {
            statusTxt = obj.testStatuses[key];
        }
        switch (statusTxt) {
            case 'NC':
                return 'darkGrayIcon';
            case 'C':
                return 'orangeIcon';
            default:
                return 'grayIcon';
        }
    }

    statusAbr(obj, key) {
        let statusTxt;
        if (key === 'iac') {
            key = 'ra';
        }
        if (obj.testStatuses && obj.testStatuses[key]) {
            statusTxt = obj.testStatuses[key];
        }
        switch (statusTxt) {
            case 'NC':
                return 'NC';
            case 'C':
                return 'C';
            case 'N':
                if (this.translate.currentLang === 'fr') {
                    return 'A';
                } else {
                    return 'N';
                }
            default:
                if (this.translate.currentLang === 'fr') {
                    return 'EA';
                }
                return 'OH';
        }
    }

    get iacIconTitle() {
        let iacIconTitle;
        if (this.person.hasIacOnHold) {
            iacIconTitle = {en: 'Activate', fr: 'Activer', y : 33};
        } else if (this.person.checkRequestsContains('typeContainsOnly', 'ra')) {
            iacIconTitle = {en: 'Resend', fr: 'Renvoyer', y : 33};
        }
        return iacIconTitle;
    }

    relabel(name) {
        switch (name) {
            case 'personality':
                return 'mpo';
            case 'communication':
                return 'dit';
            case 'communicationPrb':
                return 'ditprb';
            default:
                return name;
        }
    }

    // noinspection FunctionWithMoreThanThreeNegationsJS
    switchEvaluationType(newType) {
        let evalType = this.relabel(newType);
        if (newType !== 'iac') {
            if (this.person.checkRequestsContains('isSurvey', 'mpo')) {
                this.resendQuestionnaire(this.person.id, this.isNeededTempUser('mpo'));
            } else if (!this.person.hasMpo) {
                if (this.person.checkRequestsContains('isSurvey', 'mpo')) {

                } else if (this.person.hasMpoOnHold) {
                    let newTypeCorrected = (newType === 'personality') ? 'mpo' : newType;
                    this.activate(newTypeCorrected);
                } else {

                    this.showMpoSendRequest(evalType);
                }
                return;
            } else if (this.person.evaluations && newType !== 'communicationPrb' && !this.person.evaluations[evalType]) {
                if (this.testAndReportHandling.borderlineCase && newType === 'communication') {
                    this.reportType = newType;
                    this.peopleList.state.reportType = newType;
                    let errorMessage = (this.translate.currentLang === 'fr') ? 'Le résultat est invalide' : 'The result is invalid';
                    let errorTitle = (this.translate.currentLang === 'fr') ? 'Avertissement' : 'Warning';
                    let warnMessage = {
                        paragraph: errorMessage,
                        texth1: errorTitle,
                        position: 'center-center',
                        template: 'warningConfirm'
                    };
                    this.alertService.setConfig(warnMessage);
                } else {
                    this.activate(newType);
                }
                return;
            }

            let newTypeCondensed = (newType === 'communicationPrb') ? 'dit' : evalType;
            if (!this.peopleList.sessionData.structure.testAccessPermissions[newTypeCondensed]) {
                this.unaccessibleTest.emit(newTypeCondensed);
                return;
            }

            if (!this.questionnaireCompleted() && !this.user.isSender) {
                return;
            }

        } else {
            if (!this.person.hasIac) {
                if (this.person.checkRequestsContains('typeContainsOnly', 'ra')) {
                    this.resendQuestionnaire(this.person.id, this.isNeededTempUser('ra'), 'resendIac');
                } else {
                    this.iacRequest();
                }
            }
        }

        if (newType === 'communicationPrb') {
            evalType = 'dit';
            newType = 'communication';
            this.communicationPrb = true;
        } else {
            this.communicationPrb = false;
        }

        // Check if this evaluation type exists
        if (this.person.evaluations[evalType] &&
            this.person.evaluations[evalType].info.status
        ) {
            if (evalType === 'mpo' || newType === 'communication') {
                this.checkPrb();
            }
            evalType = newType;
            this.resendType = (evalType === 'iac') ? 'ra' : 'personality';
            this.reportType = evalType;
            this.peopleList.state.reportType = evalType;
            this.router.navigate(['m/people/view/' + this.person.id + '/' + evalType]).then(() => {});
        } else {
            if (!this.archive) {
                if (this.peopleList.sessionData.permissions.accountType === 'client' && newType === 'dit') {
                    if (
                        !(this.person.evaluations.mpo && this.person.evaluations.mpo.info.status)
                        && !this.archive
                    ) {
                        let warningParaphraph = 'Pour générer un rapport MPO Communication, vous devez au préalable activer un rapport ' +
                            'MPO Personnalité.';
                        let warningTexth1 = 'Activations d\'un rapport';
                        if (this.translate.currentLang === 'en') {
                            warningParaphraph = 'Please activate the MPO Personality in order to enable MPO Communication report ' +
                                'activation.';
                            warningTexth1 = 'Report activation';
                        }
                        this.alertService.setConfig({
                            paragraph: warningParaphraph,
                            texth1: warningTexth1,
                            template: 'prohibitedAction'
                        });
                        return;
                    }
                }
            }
        }
    }

    reportsName(forMpo = false) {
        let reportsName = '';
        let reportsNameSep = '';
        if (this.evaluationRequests && !forMpo) {
            for (let evaluation of this.evaluationRequests) {
                evaluation.type.forEach(t => {
                    reportsName += reportsNameSep + TextHelper.checkReportName(t, this.translate.currentLang);
                    reportsNameSep = ', ';
                });
            }

        } else if (this.person.evaluations) {
            let personEvaluations = this.person.evaluations;
            let initialTest = null;
            let initialReportName;
            for (let ev in personEvaluations) {
                if (ev && ev !== 'requests' && ev !== 'researchQuestions' && ev !== 'prb' && ev !== 'iac') {
                    if (personEvaluations.hasOwnProperty(ev)) {
                        if (initialTest === null ||
                            (personEvaluations[ev].info && initialTest.info && personEvaluations[ev].info.date < initialTest.info.date)) {
                            initialTest = personEvaluations[ev];
                            initialReportName = ev;
                        }
                    }
                }
            }
            reportsName = TextHelper.checkReportName(initialReportName, this.translate.currentLang);
        }

        if (reportsName !== '') {
            return reportsName;
        }
        return;
    }

    clearJob(personId) {
        this.subscriptions.add(this.apiPeopleService.editPerson([personId],
            {
                jobId: ''
            }
        ).pipe(first()).subscribe(
            () => {
                this.person.jobId = '';
                this.person.personToDisplay.jobTitle = '';
                this.person.personToDisplay.jobToDisplay = '';
                this.changeDetectorRef.markForCheck();
            }
        ));
    }

    changeProfilersSatellite(value) {
        this.jobToDisplay = value;
        this.loader = 'loading';
        this.subscriptions.add(this.apiPeopleService.editSatellite(
            [this.peopleList.state.personToDisplayId],
            {
                satelliteJobId: value
            }
        ).pipe(take(1)).subscribe(
            () => {
                this.subscriptions.add(this.apiPeopleService.record([this.peopleList.state.personToDisplayId]).pipe(take(1)).subscribe(
                    // tslint:disable-next-line:no-shadowed-variable
                    res => {
                        if (res) {
                            this.person.evaluations = res.evaluations;
                            this.getSatelliteLevelDescription();
                            this.loader = 'completed';
                            this.stateService.people.stateChanged.next({'profilerId': value});
                            this.changeDetectorRef.markForCheck();
                        }
                    }
                ));
            }
        ));

        setTimeout(() => {
            this.loader = null;
            this.changeDetectorRef.markForCheck();
        }, 2000);
    }

    getSatelliteLevelDescription() {
        this.satelliteLevel = {en: null, fr: null};
        this.satelliteDescription = {en: null, fr: null};
        if (this.testAndReportHandling.satelliteText(this.person.satelliteScore, this.translate.currentLang)) {
            this.satelliteLevel['fr'] = this.testAndReportHandling.satelliteText(this.person.satelliteScore, 'fr').level;
            this.satelliteDescription['fr'] = this.testAndReportHandling.satelliteText(this.person.satelliteScore, 'fr').text;

            this.satelliteLevel['en'] = this.testAndReportHandling.satelliteText(this.person.satelliteScore, 'en').level;
            this.satelliteDescription['en'] = this.testAndReportHandling.satelliteText(this.person.satelliteScore, 'en').text;
        }
    }

    peopleEdit() {
        this.editRequest = !this.editRequest;
        this.pdfRequest = false;
        this.closeSendResendActRequests();
    }

    showWarning(type) {
        let wType = 'MPO';
        // let cType = this.translate.instant('commons.cai');
        if (type === 'sendMpo') {
            wType = this.translate.instant('commons.cai');
            // cType = 'MPO';
        }

        let txtWarning = {
            paragraph: this.translate.instant('commons.questionnaireAlreadySent', {qType: wType}),
            texth1: this.translate.instant('commons.alertWarning'),
            position: 'center-center',
            template: 'freezeAction'
        };
        this.alertService.setConfig(txtWarning);
    }

    iacRequest() {
        if (this.displayResendAndDeleteMpo) {
            this.showWarning('sendIac');
        } else {
            this.iacSendRequest = true;
            this.editRequest = false;
            this.pdfRequest = false;
            this.activationRequest = false;
            this.resendRequest = false;
            this.mpoSendRequest = false;
            this.prbSendRequest = false;
        }
    }

    closeSendResendActRequests() {
        this.activationRequest = false;
        this.iacSendRequest = false;
        this.resendRequest = false;
        this.mpoSendRequest = false;
        this.prbSendRequest = false;
    }

    closeAllRequestsPanels(refresh = false) {
        this.activationRequest = false;
        this.pdfRequest = false;
        this.editRequest = false;
        this.iacSendRequest = false;
        this.resendRequest = false;
        this.mpoSendRequest = false;
        this.prbSendRequest = false;
        if (this.activationData) {
            this.peopleList.state.reportType = this.activationData.type;
            this.reportType = this.activationData.type;
            this.activationData = undefined;
        }
        if (this.user.isSender) {
            if (this.matInfoPanel) {
                this.matInfoPanel.expanded = true;
            } else {
                this.expandInfoPanel = true;
            }
        }

        if (refresh) {
            this.person.setPersonToDisplayWithId(this.person.id, 'mobileView', 'people', this.reportType);
        }
    }

    closeResendRequest(refresh = false) {
        this.expandInfoPanel = true;
        this.closeAllRequestsPanels(refresh);
    }

    checkPersonJob() {
        if (this.person.jobId) {
            let jobSubscription = this.apiJobs.job([this.person.jobId]).subscribe(
                res => {
                    if (res) {
                        this.expandInfoPanel = false;
                        if (this.user.isSender) {
                            this.matInfoPanel.expanded = true;
                        }
                        jobSubscription.unsubscribe();
                    }
                }
            );
        } else {
            if (this.user.isSender) {
                if (this.matInfoPanel) {
                    this.matInfoPanel.expanded = true;
                } else {
                    this.expandInfoPanel = true;
                }
            }
        }
    }

    togglePortraitButtons() {
        this.showPortraitButtons = !this.showPortraitButtons;
        // if (this.showPortraitButtons) {
        //     this.idleInterval = setInterval(() => {
        //         this.idleTime++;
        //         if (this.idleTime > 3) { // 3 sec
        //             this.showPortraitButtons = false;
        //             clearInterval(this.idleInterval);
        //             this.changeDetectorRef.markForCheck();
        //         }
        //     }, 1000);
        // }
    }

    hidePortraitButtons() {
        this.showPortraitButtons = false;
    }

    get colsNumber() {
        let col = 0;
        if (this.stateService.hasPersonality) {
            col++;
        }
        if (this.stateService.hasCommunication) {
            col++;
        }
        if (this.stateService.hasTalents) {
            col++;
        }
        if (this.stateService.hasSatellite) {
            col++;
        }
        if (this.stateService.hasIac) {
            col++;
        }
        return col;
    }

    onResize(event) {
        if (event && event.target && event.target.innerWidth) {
            this.setGraphDim(event.target.innerWidth);
            this.changeDetectorRef.markForCheck();
        }
    }

    setGraphDim(w) {
        this.graphWidth = w;
        if (this.graphWidth < 385) {
            this.prbGridCols = 4;
        } else {
            this.prbGridCols = 3;
        }
        this.graphHeight = this.graphWidth / 2.4256;
        this.graphHeight = +this.graphHeight.toFixed(2);
    }

    ngOnInit() {
        if (this.urlReportType) {
            // make sure that the report type is set in the state
            this.peopleList.state.reportType = this.urlReportType;
        }
        if (!this.peopleList.state.personToDisplayId) {
            // make sure that the person id is set in the state
            this.peopleList.state.personToDisplayId = this.person.id;
        }
        this.sessionData = this.peopleList.sessionData;
        this.setGraphDim(window.innerWidth);
        this.queryReport = false;

        this.stateService.people.stateChanged$.subscribe(
            () => {
                this.changeDetectorRef.markForCheck();
            }
        );

        this.isLandscape = window.matchMedia('(orientation: landscape)').matches;
        let thisObject = this;
        window.addEventListener('orientationchange', function () {
            thisObject.isLandscape = !thisObject.isLandscape;
            if (thisObject.isLandscape) {
                thisObject.hidePortraitButtons();
            }
            // set the graph width by the previous window height
            thisObject.graphWidth = window.innerHeight;
            thisObject.graphHeight = thisObject.graphWidth / 2.2256;
            thisObject.graphHeight = +thisObject.graphHeight.toFixed(2);
            thisObject.changeDetectorRef.markForCheck();
        }, false);

        this.titleSep = TextHelper.checkTitleSep(this.translate.currentLang);
        this.pDateFormat = TextHelper.dateFormat(this.translate.currentLang);

        if (!this.person.id && this.route.snapshot.url && this.route.snapshot.url[3]) {
            this.person.setPersonToDisplayWithId(this.route.snapshot.url[3].path, 'mobileView');
        }

        if (this.person.id) {
            if (!this.user.isSender) {
                if (this.person.personalityReport) {
                    this.testAndReportHandling.getReport('personality', this.translate.currentLang, this.peopleList.state.prbIndex);
                    this.communicationPrb = (this.originReportType === 'ditprb');
                }
            }

            this.checkPersonJob();

            for (let evaluation of ['dit', 'mpo', 'talents', 'satellite']) {
                if (
                    this.person.evaluations &&
                    this.person.evaluations[evaluation] &&
                    this.person.evaluations[evaluation].info.status
                ) {
                    this.queryReport = true;
                    // noinspection BreakStatementJS
                    break;
                }
            }

            if (this.person.satelliteReport) {
                this.subscriptions.add(this.apiJobs.jobs([{'isSatellite': 1, 'fields': 'id,jobTitle'}]).pipe(first()).subscribe(
                    (jobsRes) => {
                        this.satelliteJobs = jobsRes.data;
                        this.changeDetectorRef.markForCheck();
                    })
                );
            }

            this.reportType = this.peopleList.state.report.type;
            if ((this.reportType === 'ra' || this.reportType === 'iac') && !this.stateService.hasIac) {
                this.reportType = 'mpo';
            }
            this.resendType = (this.reportType === 'iac') ? 'ra' : 'mpo';

            // Evaluation Requests
            this.evaluationRequests = this.getPersonRequests();

            if (this.person.satelliteScore) {
                this.getSatelliteLevelDescription();

                this.jobToDisplay = (
                    this.person.evaluations.satellite.info &&
                    this.person.evaluations.satellite.info.job
                ) ? this.person.evaluations.satellite.info.job : '';
            } else {
                this.jobToDisplay = '';
            }
            if (this.person.satelliteScore && this.translate.currentLang) {
                this.getSatelliteLevelDescription();
            }

            if (this.person.jobId && !(!this.person.satelliteScore || this.person.satelliteScore === 'noJobProfile')) {
                // noinspection UnusedCatchParameterJS
                try {
                    this.subscriptions.add(this.apiJobs.job([this.person.jobId]).pipe(first()).subscribe(
                        (resJobs) => {
                            // this.person.jobPosition = res.jobTitle;
                            if (resJobs.archive) {
                                this.clearJob(this.person.id);
                            }
                        }
                    ));
                } catch (e) {
                    this.clearJob(this.person.id);
                }
            }

            this.checkReports();

            if (this.route.snapshot.url[4] &&
                this.route.snapshot.url[4].path &&
                this.peopleList.state.reportBack &&
                this.peopleList.state.reportBack.module !== 'people') {
                this.switchEvaluationType(this.route.snapshot.url[4].path);
            }
        }
    }

    checkReports() {
        // MPO
        if ((this.reportType === 'personality' || (this.reportType === 'dit' && !this.communicationPrb))
        ) {
            if (!this.person.personalityReport || (this.person.personalityReport && !this.person.personalityReport.scores)) {
                if (this.getPersonRequest()) {
                    this.requestedBy = this.getPersonRequest().requestedBy;
                }
            }

            // PRB
            this.checkPrb();
        }

        // DIT
        if (this.person.communicationReport) {
            this.communicationPortraitName =
                this.testAndReportHandling.renderQuad(this.person.communicationReport.info.quad);

            this.communicationQuad = this.person.communicationReport.info.quad;
            this.communicationAd = this.person.communicationReport.info.adaptability;

            this.communicationGraphType = 'active';
            this.requestedBy = (this.person.communicationReport.info.requestedBy) ? this.person.communicationReport.info.requestedBy.fullName : '';
        } else {
            this.communicationQuad = '';
            if (this.testAndReportHandling.borderlineCase) {
                this.communicationQuad = 'INV';
            }
            this.communicationAd = null;
            this.communicationGraphType = 'inactive';
            if (this.getPersonRequest()) {
                this.requestedBy = this.getPersonRequest().requestedBy;
            }
        }

        this.updateIdwPrbGraph();
    }

    get personalityIconTitle() {
        if (this.person.hasAnMpoQuestionnaireSent) {
            return {en: 'Resend', fr: 'Renvoyer', y : 33};
        } else if (this.person.hasPersonalityOnHold || (this.person.hasAnMpoReport && !this.person.hasPersonality)) {
            return {en: 'Activate', fr: 'Activer', y: 33};
        } else {
            return {en: 'Send', fr: 'Envoyer', y : 33};
        }
    }

    get satelliteIconTitle() {
        // Whether Satellite is on hold or has an MPO questionnaire but no satellite activated
        if (this.person.hasAnMpoQuestionnaireSent) {
            return {en: 'Resend', fr: 'Renvoyer', y: 33};
            // Has a satellite report but no score
        } else if (this.person.hasSatelliteOnHold || (this.person.hasAnMpoReport && !this.person.hasSatellite)) {
            return {en: 'Activate', fr: 'Activer', y: 33};
            // MPO questionnaire filled but not answered
        } else if (this.person.hasSatellite && (this.person.satelliteScore === 'noJobProfile' || !this.person.satelliteScore)) {
            return {en: 'Choose a ', fr: 'Choisir un ', secen: 'job', secfr: 'poste', y: 29};
        } else {
            return {en: 'Send', fr: 'Envoyer', y : 33};
        }
    }

    get communicationIconTitle() {
        if (this.testAndReportHandling.borderlineCase) {
            return {en: 'Invalid', fr: 'Invalide', y: 33};
        } else if (this.person.hasAnMpoQuestionnaireSent) {
            return {en: 'Resend', fr: 'Renvoyer', y: 33};
            // Has a satellite report but no score
        } else if (this.person.hasCommunicationOnHold || (this.person.hasAnMpoReport && !this.person.hasCommunication)) {
            return {en: 'Activate', fr: 'Activer', y: 33};
        } else {
            return {en: 'Send', fr: 'Envoyer', y : 33};
        }
    }

    get talentsIconTitle() {
        if (this.person.hasAnMpoQuestionnaireSent) {
            return {en: 'Resend', fr: 'Renvoyer', y : 33};
        } if (this.person.hasTalentsOnHold || (this.person.hasAnMpoReport && !this.person.hasTalents)) {
            return {en: 'Activate', fr: 'Activer', y: 33};
        } else {
            return {en: 'Send', fr: 'Envoyer', y : 33};
        }
    }

    get personSatellite() {
        if (this.person.evaluations && this.person.evaluations.satellite) {
            return this.person.evaluations.satellite;
        }
        return null;

    }

    get personDIT() {
        if (this.person.evaluations && this.person.evaluations.dit) {
            return this.person.evaluations.dit;
        }
        return null;
    }

    get personTalents() {
        if (this.person.evaluations && this.person.evaluations.talents) {
            return this.person.evaluations.talents;
        }
        return null;
    }

    get satelliteDate() {
        let satelliteDate;
        if (this.personSatellite) {
            satelliteDate = this.personSatellite.info.date;
        } else {
            satelliteDate = '';
        }
        return satelliteDate;
    }

    get talentsDate() {
        let talentsDate;
        if (this.personTalents) {
            talentsDate = this.personTalents.info.date;
        } else {
            talentsDate = '';
        }
        return talentsDate;
    }

    get prbCompletionDate() {
        let prbCompletionDate = null;
        if (this.person.evaluations && this.person.evaluations.prb && this.person.evaluations.prb.list) {
            this.person.evaluations.prb.list.forEach(r => {
                if (r.info && r.info.date) {
                    if (!prbCompletionDate || r.info.date > prbCompletionDate) {
                        prbCompletionDate = r.info.date;
                    }
                }
            });
            return prbCompletionDate;
        }
        return;
    }

    get isExtraPrbSent() {
        return !!(this.testAndReportHandling.extraPrbStatus !== 'Aucun' && this.testAndReportHandling.extraPrbStatus !== 'None');
    }

    get prbToDisplay(): any {
        return this.testAndReportHandling.getPrbData(this.person.prbReport, this.peopleList.statePeople.prbIndex, this.mpoGraphType);
    }

    get mpoGraphType() {
        if (!this.person.hasPersonality || (this.person.hasPersonality && !this.person.personalityScores)) {
            return 'inactive';
        }
        return (this.config === 'report') ? 'report' : 'plain';
    }

    setPrbIndex(prbIndex: number) {
        // Set PRB index in state
        this.stateService.people.prbIndex = prbIndex;
        // update prb data to display
        this.prb = this.testAndReportHandling.getPrbData(this.getPersonPRB(), prbIndex, this.mpoGraphType);
        this.updateIdwPrbGraph();

        this.changeDetectorRef.markForCheck();
    }

    onPdfShare() {
        this.pdfRequest = !this.pdfRequest;
        this.editRequest = false;
        this.closeSendResendActRequests();
    }

    get hasReportAccess() {
        return !!(this.stateService.hasMpoTestAccess || this.stateService.hasIac);
    }

    /**
     * whether or not to display the resend / delete options for an mpo questionnaire
     */
    get displayResendAndDeleteMpo() {
        return !!(
            !this.person.hasMpo && this.person.checkRequestsContains('isSurvey', 'mpo') &&
            (
                this.person.checkRequestsContains('variant', '1,1') ||
                this.person.checkRequestsContains('variant', '1,0')
            )
        );
    }

    /**
     * whether or not to display the resend / delete options for an iac questionnaire
     */
    get displayResendAndDeleteIac() {
        return !!(!this.person.iacReport && this.person.checkRequestsContains('isSurvey', 'iac'));
    }

    get questionnaireBlockTitles() {
        let blocs = {
            first: {
                title: null,
                type: null
            },
            second: {
                title: this.translate.instant('commons.additionalPrb')
            },
            third: {
                title: null
            }
        };
        if (this.person.hasAnMpoReport || this.person.iacReport) {
            // There is an mpo questionnaire filled
            if (this.person.hasMpo) {
                // If MPO sent along IAC
                if (this.person.iacCompletionDate && this.person.mpoCompletionDateToDisplay === this.person.iacCompletionDateToDisplay) {
                    blocs.first.title = this.translateService.instant('commons.mpoCai');
                    this.showIacQuestionnaire = false;
                } else {
                    blocs.first.title = 'MPO';
                    blocs.third.title = this.translate.instant('commons.cai');
                    this.showIacQuestionnaire = true;
                }
                // First block type / if Prb and traits has been sent together
                blocs.first.type =
                    (
                        this.person.firstPrbCompletionDate &&
                        this.person.mpoCompletionDateToDisplay === this.person.firstPrbCompletionDateToDisplay
                    ) ? '1,1' : '1,0';
                // If a report est active, we may send another PCR so display the title
                if (this.person.hasAnMpoReport) {
                    blocs.second.title = this.translateService.instant('commons.additionalPrb');
                } // else, display nothing.

                // There is an iac questionnaire filled (and no MPO filled)
            } else {

                // First blocks title
                blocs.first.title = 'MPO'; // no MPO filled => no type
                blocs.third.title = this.translateService.instant('commons.cai');
                this.showIacQuestionnaire = true;
            }
            // Request in the pipeline
        } else {
            // MPO request?
            if (this.person.checkRequestsContains('typeContains', 'mpo')) {
                // check if the request contains also IAC
                if (this.person.checkRequestsContains('typeContains', 'ra')) {
                    blocs.first.title = this.translateService.instant('commons.mpoCai');
                    this.showIacQuestionnaire = false;
                    // request contains only MPO
                } else {
                    blocs.first.title = 'MPO';
                    blocs.third.title = this.translateService.instant('commons.cai'); // display this anyway, they are necessary apart
                    this.showIacQuestionnaire = true;
                }
                blocs.first.type = (this.person.checkRequestsContainsMpoPrb()) ? '1,1' : '1,0';
                // Not MPO, must be IAC
            } else {
                blocs.first.title = 'MPO';
                if (this.person.hasAnMpoReport) {
                    blocs.second.title = this.translateService.instant('commons.additionalPrb');
                }
                blocs.third.title = this.translateService.instant('commons.cai'); // display this anyway, they are necessary apart
                this.showIacQuestionnaire = true;
            }
            if (this.person.requests && this.person.requests[0]) {
                let typeArr = this.person.requests[0].type;
                if (typeArr) {
                    let typeSep = '';
                    blocs.first.type = '';
                    typeArr.forEach(type => {
                        if (type === 'mpo') {
                            type = 'personality';
                        }
                        if (type === 'dit') {
                            type = 'communication';
                        }
                        if (type === 'ra') {
                            type = 'cai';
                        }
                        blocs.first.type = blocs.first.type + typeSep + this.translateService.instant('commons.' + type);
                        typeSep = ', ';
                    });
                }
            }
        }
        return blocs;
    }

    onToggleSherpa(checked: boolean) {
        this.person.onToggleSherpa(this.person.id, this.stateService.people.report.type, checked);
    }

    isSherpaRoute() {
        return this.person.isSherpaRoute(this.person.id, this.stateService.people.report.type);
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

}
